/* Footer 2 */
.footer-2 {
  .footer-middle {
    padding-top: 4.5rem;
    padding-bottom: 0.4rem;
  }

  .footer-bottom .container {
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    flex-direction: column-reverse;
  }
}

.col-md-6 .widget-about p,
.col-lg-6 .widget-about p {
  max-width: none;
}

.widget-about-info {
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding-top: 0.7rem;

  .footer-payments {
    margin-top: 0.6rem;
  }
}

.widget-about-title {
  display: block;
  font-weight: 300;
  font-size: 1.3rem;
  color: #333333;
  letter-spacing: 0;
  margin-bottom: 0.4rem;
}

.footer-bottom {
  .social-icons {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    margin-top: -10px;

    @include mq('sm', 'max') {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  
    > * {
      margin-top: 10px;
      
      @include mq('sm', 'max') {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }

  .social-icon {
    font-size: 1.3rem;
  }
}

.footer-newsletter {
  color: #ebebeb;
  padding-top: 4.8rem;
  padding-bottom: 5.5rem;

  .heading {
    margin-bottom: 2.4rem;
  }

  .title {
    color: #fff;
    font-weight: 500;
  }

  .title-desc {
    color: inherit;
    font-size: 1.6rem;
    margin-top: 0.7rem;
    span {
      color: #fff;
      font-weight: 400;
    }
  }

  .form-control {
    height: 46px;
    padding: 1.25rem 2rem;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 300;
    color: #999999;
    background-color: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    transition: all 0.3s;

    &.form-control::placeholder {
      color: #999999;
    }
  }

  .btn {
    i:last-child {
      margin-left: 1rem;
    }
  }
}

.footer-menu {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  margin-left: auto;
  margin-right: auto;

  li {
    position: relative;
    & + li {
      margin-left: 2.1rem;

      &:before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 1.2rem;
        position: absolute;
        left: -1.1rem;
        top: 50%;
        margin-top: -0.6rem;
        background-color: #b5b5b5;
      }
    }
  }

  a {
    box-shadow: 0 1px 0 #b5b5b5;
  }
}

@include mq('lg') {
  .footer-2 {
    .footer-bottom .container,
    .footer-bottom .container-fluid {
      flex-direction: row;
    }
  }

  .footer-menu {
    margin-bottom: 0;
    margin-left: 0.6rem;
    margin-right: 0;
  }

  .footer-bottom {
    .social-icons {
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

@include mq('sm', 'max') {
  .widget-about-info {
    .col-sm-6:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
