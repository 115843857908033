.inline-select-row {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 2rem;

  label {
    display: inline-block;
    width: 120px;
    font-weight: 400;
    font-size: 1.4rem;
    margin-bottom: 0;
    word-break: normal;
  }
}

@include mq('md', 'max') {
  .inline-select-row label {
    width: 90px;
  }
}

@include mq('xs', 'max') {
  .inline-select-row label {
    flex: 1 0 90px;
  }
}

.inline-select-selector {
  position: relative;
  flex: 1 0 220px;
}

.inline-select-form-control {
  margin-bottom: 0;
}
