/* 404 - Error */
.error-content {
  color: #777;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.025em;
  padding-top: 8.5rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 480px;
  background-color: #fdfdfd;

  p {
    margin-bottom: 2.5rem;
  }
}

.error-title {
  letter-spacing: -0.025em;
  margin-bottom: 1.4rem;
}

@include mq('md') {
  .error-content {
    padding-top: 12.5rem;
    min-height: 540px;
  }

  .error-content {
    min-height: 620px;
  }
}
