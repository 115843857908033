.notification {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1.3rem 0;
  background-color: #911d1e;
}
.notification .notify-content h3 {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: #fff;
  margin-bottom: 0;
}
.notification .notify-content .timer {
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  color: #fff;
  margin: 0 1rem;
}
.notification .notify-action {
  position: absolute;
  right: 10%;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.notification .notify-action a {
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  color: #fff;
  line-height: 0;
}

@include mq('sm', 'max') {
  .notification .notify-content {
    margin: 0 7.5%;
  }

  .notification .notify-action {
    position: absolute;
    right: 3%;
  }
}
