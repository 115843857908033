/* Modals */
.modal-backdrop {
  background-color: #333;

  &.show {
    opacity: 0.4;
  }
}

.modal-body {
  padding: 0;

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
}

.modal-content {
  border: none;
  box-shadow: 5px 10px 16px rgba(51, 51, 51, 0.05),
    -5px 10px 16px rgba(51, 51, 51, 0.05);
}

.close {
  font-weight: normal;
  font-size: 1.8rem;
  color: $body-text;
  opacity: 1;
  transition: all 0.3s;

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

@include mq('md') {
  .modal-dialog {
    max-width: 575px;
  }
}

@include mq('lg') {
}

@include mq('sm', 'max') {
}

.modal-close {
  position: absolute;
  font: normal normal 2.6rem/1 'molla';
  color: #666;
  font-size: 1.8rem;
  top: 1.5rem;
  right: 2rem;
  display: block;
  width: 2rem;
  height: 2rem;
}

.ReactModal__Content {
  > * {
    opacity: 0;
    transition: 0.3s ease;

    &.modal-content {
      transform: translateY(-30vh);
    }
  }
  &.ReactModal__Content--after-open > * {
    opacity: 1;

    &.modal-content {
      transform: translateY(0);
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.3s;

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }
}

.video-modal {
  position: relative;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  max-width: 106rem;
  width: 100%;
  height: 59.6rem;
  background-color: #eee;
  iframe {
    width: 100%;
    height: 100%;
  }

  .close {
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 5rem;
    height: 5rem;
  }
}
