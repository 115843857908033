.order-product-title {
  color: #333333;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: -0.025em;
  margin-bottom: 0.9rem;
}

.order-total-col {
  width: 9rem;
}

.order-quantity-col {
  color: $dark-text;
  font-weight: 600;
  font-size: 1.5rem;
}

.human-banner-title {
  font-size: 6rem;
}

@include mq('md', 'max') {
  .human-banner-title {
    font-size: 5rem;
  }
}

@include mq('sm', 'max') {
  .human-banner-title {
    font-size: 4rem;
  }
}

.how-to-order-table {
  th,
  td {
    color: #333;
  }
  th {
    text-align: center;
    font-weight: 600;
  }

  .first-col {
    font-style: italic;
    text-align: center;
  }

  .other-col {
    text-align: center;
  }
}

.recom-meal-title {
  font-size: 9rem;
  text-align: center;
  margin-bottom: 0;
}

@include mq('md', 'max') {
  .recom-meal-title {
    font-size: 8rem;
  }
}

@include mq('sm', 'max') {
  .recom-meal-title {
    font-size: 6.5rem;
  }
}
