/* Demo 8 Homepage 8 */
@import "../mixins/clearfix";
@import "../mixins/breakpoints";
@import "../mixins/lazy";
@import "../mixins/buttons";

@import "../config/fonts";
@import "../config/variables";

$intro-slide-title-lg: 10rem;
$intro-slide-title-md: 6rem;
$intro-slide-title-sm: 4rem;
$intro-slide-title-xs: 3rem;

.heading {
  margin-bottom: 2.3rem;

  .title {
    margin-bottom: 0.6rem;
    font-size: 2.4rem;
    font-weight: 600;
  }
}

.title-desc {
  font-size: 1.5rem;
}

hr {
  border-top-color: #e9e9e9;
}

.intro-slider .intro-slide {
  min-height: 700px;
  height: 700px;
}

.intro-slider {
  &.owl-theme.owl-nav-inside .owl-dots {
    bottom: 20px;
  }
}

.intro-slide {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;

  .intro-content {
    position: static;
    left: auto;
    transform: translateY(0);
    -ms-transform: translateY(0);
  }
}

.intro-slider-container .owl-carousel .owl-item {
  img {
    width: auto;
    position: absolute;
    top: -6rem;
    z-index: 1;
  }

  img.position-right {
    left: 43.5%;
  }

  img.position-left {
    left: 16%;
  }
}

.intro-content {
  img {
    position: absolute;
    right: 0;
  }

  z-index: 0;

  &.text-left {
    padding-left: 18.5rem;
  }

  &.text-right {
    padding-right: 11rem;
  }

  .highlight {
    color: $primary-color;
  }
}

.intro-subtitle {
  font-weight: 400;
  font-size: 1.675rem;
  margin-bottom: 0.5rem;
  color: #777;
  text-shadow: #fff 0 0 15px;
  // text-transform: uppercase;

  &.dark {
    color: #fff;
    text-shadow: #000000ba 0 0 15px;
  }
}

.intro-title {
  font-size: $intro-slide-title-lg;
  letter-spacing: 0.0025em;
  font-weight: 700;
  color: #222;
  text-shadow: #fff 0 0 15px;
  // text-transform: uppercase;
  line-height: 1.05em;
  margin-bottom: 0.2rem;

  &.dark {
    color: #fff;
    text-shadow: #000000ba 0 0 15px;
  }

  strong {
    font-size: 18rem;
    color: $primary-color;
  }
}

.intro-text {
  color: #333333;
  font-weight: 200;
  font-size: 3.2rem;
  line-height: 1.16;
  letter-spacing: -0.025em;
}

.owl-theme .owl-nav [class*="owl-"] {
  width: 3.2rem;
  height: 3.2rem;
  font-size: 2rem;
  margin-top: -1.6rem;
}

.owl-theme.owl-light .owl-nav [class*="owl-"] {
  border-color: #fff;
  color: #fff;
}

.brands {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: -5rem;
    height: 0.1rem;
    background-color: #ebebeb;
  }
}

.banner-subtitle {
  font-weight: 300;
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.banner-title {
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 1.4em;
  margin-bottom: 2rem;
  text-transform: uppercase;

  strong {
    font-weight: 700;
  }
}

.banner-group {
  .banner-title {
    margin-bottom: 4rem;
  }
}

.banner {
  .banner-link.btn {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 140px;
    color: $primary-color;
    border: 0.2rem solid $primary-color;

    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.banner-content {
  padding-top: 3.4rem;
  &.banner-content-right {
    right: 40px;
  }
}

.banner-big {
  color: $body-text;
  font-size: 1.5rem;
  line-height: 1.6;

  .banner-content {
    padding-top: 0;
    max-width: 446px;
    left: 7rem;
  }

  .banner-subtitle {
    font-weight: 300;
    font-size: 1.4rem;
  }

  .banner-title {
    font-weight: 700;
    font-size: 5rem;
    letter-spacing: 0.01em;
    margin-bottom: 1rem;
  }

  p {
    letter-spacing: 0;
    font-size: 1.3rem;
    line-height: 1.8em;
  }

  p:last-of-type {
    margin-bottom: 3.3rem;
  }

  .btn {
    padding-top: 1.15rem;
    padding-bottom: 1.15rem;
    min-width: 160px;
    text-transform: uppercase;
    background-color: transparent;
    border: 0.2rem solid #fff;
    letter-spacing: 0.1em;

    &:hover,
    &:focus {
      background-color: #fff;
      color: #222;
      text-decoration: none;
    }
  }
}

.nav.nav-border-anim .nav-link:before {
  background-color: #333;
}

.nav-pills.nav-big {
  .nav-item + .nav-item {
    margin-left: 3.5rem;
  }

  .nav-link {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    color: #cccccc;
    font-weight: 600;
    font-size: 2.4rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  .nav-item .nav-link {
    &:hover,
    &:focus,
    &.active {
      color: #333;
    }

    &.active {
      border-bottom-color: #333;
    }
  }

  .nav-item.show .nav-link {
    color: #333;
  }
}

.nav.nav-pills:not(.nav-big) .nav-link:not(:hover):not(:focus):not(.active) {
  color: #666;
}

/* Product */
.product.product-2 {
  background-color: #fff;
  .product-action-vertical {
    top: 2rem;
  }

  .product-cat {
    color: #ccc;
  }

  .product-body {
    text-align: center;
  }

  .product-media {
    &:before {
      @include aspect-ratio(800, 1088);
    }
  }

  .product-price {
    justify-content: center;
    color: #333;
  }

  .product-nav {
    justify-content: center;
  }

  .product-action {
    background-color: #222;
    padding-top: 0;
    padding-bottom: 0;
  }
  .btn-product {
    color: #ccc;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;

    span {
      color: #fff;
      text-transform: uppercase;
      margin-left: -1.35rem;
    }

    &::before {
      color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: all 0.1s;
    }

    &:hover,
    &:focus {
      color: #fff;
      background-color: $primary-color;
      span {
        color: #fff;
        margin-left: 0;
        box-shadow: none;
      }
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .product-nav-thumbs a,
  .product-nav-thumbs span {
    height: 4.4rem;
  }
  .btn-product-icon {
    background-color: #222;
    color: #fff;
    &:hover,
    &:focus {
      background-color: $primary-color;
    }
  }
}

.trending {
  position: relative;
  img {
    min-height: 315px;
    object-fit: cover;
  }
  .banner {
    position: static;
  }
  .banner-content {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.new-arrivals {
  .banner-content {
    padding-top: 0;
  }
  .banner-title {
    letter-spacing: 0.01em;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .banner-subtitle {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  .btn.banner-link {
    font-size: 1.3rem;
    letter-spacing: 0.1em;
    i:last-child {
      margin-left: 0;
      font-size: 1.5rem;
      letter-spacing: -0.01em;
    }
  }
}

.recent-arrivals {
  .heading.heading-flex {
    flex-direction: column;
  }
  .nav.nav-pills .nav-link {
    font-size: 1.4rem;
    letter-spacing: 0.01em;
  }
  .nav.nav-border-anim .nav-link:before {
    background-color: $primary-color;
  }
  .heading .title {
    margin-bottom: 1rem;
  }
}

.left-banner-container {
  margin-bottom: 0 !important;

  & > div {
    height: 100%;
  }

  .full-height {
    position: absolute;
    height: 100%;
    top: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 2em !important;

    & > div {
      height: auto;
    }

    .full-height {
      position: relative;
    }
  }
}

.btn-more {
  padding: 1.15rem 1rem;
  min-width: 160px;
  text-transform: uppercase;
  border: 0.2rem solid #222;
  color: #222;
  font-size: 1.3rem;
  letter-spacing: 0.1em;

  &:hover,
  &:focus {
    background-color: #222;
    color: #fff;
    border-color: #222;
  }
}

.icon-box-card {
  padding-top: 3rem;
  padding-bottom: 3.2rem;
  border: 0.2rem solid #ebebeb;
  background-color: transparent;
  transition: all 0.3s;

  .icon-box-icon {
    font-size: 3rem;
    color: $primary-color;
  }

  .icon-box-title {
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-bottom: 0.3rem;
  }

  p {
    font-size: 1.3rem;
  }

  &:hover,
  &:focus {
    color: #fff;
    border-color: $primary-color;
    background-color: $primary-color;

    .icon-box-title,
    .icon-box-icon,
    p {
      color: #fff;
    }
  }
}

.instagram {
  padding-top: 2.7rem;
  .heading {
    .title {
      font-size: 2.2rem;
      margin-bottom: 1rem;
    }
    .title-desc {
      font-size: 1.3rem;
    }
    margin-bottom: 2.5rem;
  }
}

.nav {
  width: 100%;
}

@include mq("sm") {
  .intro-text {
    font-size: 3.6rem;
  }
}

@include mq("md") {
  .intro-content {
    .btn {
      margin-top: 1rem;
    }
  }

  .intro-subtitle {
    font-size: 1.6rem;
  }

  .intro-content.text-left {
    padding-left: 13rem;
  }

  .intro-title {
    font-size: $intro-slide-title-lg;

    strong {
      font-size: 18rem;
      color: $primary-color;
    }
  }

  .intro-text {
    font-size: 4.4rem;
  }

  .nav-pills.nav-big {
    .nav-link {
      font-size: 2.2rem;
    }
  }
}

@include mq("lg") {
  .intro-content.text-left {
    padding-left: 18rem;
  }
  .intro-content.text-right {
    padding-right: 18rem;
  }
  .intro-subtitle {
    font-size: 1.8rem;
  }
  .intro-title {
    font-size: $intro-slide-title-lg;

    strong {
      font-size: 18rem;
      color: $primary-color;
    }
  }

  .intro-text {
    font-size: 5.2rem;
  }

  .pin-dropdown {
    display: block;
  }

  .banner-title {
    font-size: 2.2rem;
    margin-bottom: 3.6rem;
  }

  .banner-group {
    .banner-title {
      margin-bottom: 1.8rem;
    }
  }
}

@include mq("xl") {
  .intro-content.text-right {
    padding-right: 11rem;
  }
  .intro-content {
    .btn {
      min-width: 180px;
      margin-top: 3rem;
    }
  }

  .intro-subtitle {
    font-size: 1.8rem;
  }

  .intro-title {
    font-size: $intro-slide-title-lg;

    strong {
      font-size: 18rem;
      color: $primary-color;
    }
  }

  .intro-text {
    font-size: 6rem;
  }

  .owl-theme .owl-nav [class*="owl-"] {
    width: 4rem;
    height: 4rem;
    font-size: 2.2rem;
    margin-top: -2rem;
  }
}

@include mq("md", "max") {
  .intro-content.text-left {
    padding-left: 3rem;
  }

  .intro-title {
    font-size: $intro-slide-title-md;

    strong {
      font-size: 15rem;
      color: $primary-color;
    }
  }

  .intro-content .btn {
    margin-top: 1rem;
  }

  .intro-slider-container .owl-carousel .owl-item img {
    display: none;
  }

  .intro-content.container {
    text-align: center !important;
    padding: 0;
    .intro-title {
      line-height: 1.2em;
    }
  }
}
@media screen and (max-width: 1400px) {
  .intro-slider-container .owl-carousel .owl-item img.position-left {
    left: -1%;
  }
}

@include mq("xl", "max") {
  .intro-content.text-right {
    padding-right: 11rem;
  }
}

@include mq("375px", "max") {
  .btn-product {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .nav-pills.nav-big {
    .nav-link {
      font-size: 1.4rem;
    }

    .nav-item + .nav-item {
      margin-left: 0rem;
    }
  }

  .intro-title {
    font-size: $intro-slide-title-sm;

    strong {
      font-size: 11.8rem;
    }
  }
}

.product.product-2 {
  .product-action:before {
    content: none;
  }

  .product-media > a {
    line-height: 0;
  }
}
