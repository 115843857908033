@import 'styles/config/variables';
@import '../mixins/breakpoints';

.toast-container {
  width: 260px !important ;

  .Toastify__toast--success {
    background-color: #68b978;
    border-radius: 0.5rem;
  }

  .Toastify__toast--error {
    background-color: #dc3545;
    border-radius: 0.5rem;
  }

  .Toastify__close-button {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 12px;
  }

  .Toastify__toast {
    min-height: 55px;
  }

  .Toastify__toast-body {
    padding: 1rem;
    line-height: 1.2;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-family: $font-family;
  }

  .Toastify__progress-bar {
    opacity: 0 !important;
  }
}

@include mq('480px', 'max') {
  .Toastify__toast-container {
    left: auto !important;
    right: 0px !important;
  }
}

.toast-message {
  font-family: $font-family;
  line-height: 1.2;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
