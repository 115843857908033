/* Icon boxes */
.icon-box {
  color: #777;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  margin-bottom: 2rem;

  &.icon-box-sm {
    font-size: 1.4rem;
    line-height: 1.86;
  }
}

.icon-box-title {
  color: #333333;
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: -0.025em;
  margin-bottom: 0.2rem;
}

.icon-box-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 2rem;
}

.icon-box-content p:last-child {
  margin-bottom: 0;
}

.icon-box-left {
  position: relative;
  padding-left: 70px;

  .icon-box-icon {
    position: absolute;
    left: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.icon-box-circle {
  .icon-box-icon {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    color: #fff;
    background-color: $primary-color;
  }

  &.icon-box-left {
    padding-left: 100px;

    .icon-box-icon {
      left: 1rem;
    }
  }
}

/* Icon Box Card */
.icon-box-card {
  background-color: #fafafa;
  padding: 6.5rem 2rem 6rem;

  .icon-box-title {
    font-weight: 600;
  }

  .icon-box-icon {
    margin-bottom: 1.3rem;
  }
}

/* Icon Boxes */
.icon-boxes-container {
  background-color: #f7f7f7;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  margin-bottom: 0;

  &.bg-dark {
    .icon-box-icon {
      color: $body-text;
    }

    .icon-box-title {
      color: #fff;
    }
  }
}

.icon-box-side {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;

  p {
    margin-bottom: 0;
  }

  .icon-box-icon {
    display: inline-block;
    line-height: 0;
    min-width: 7rem;
    text-align: center;
    margin-bottom: 0;
  }

  .icon-box-title {
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: -0.01em;
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
}

@include mq('md') {
  .icon-box {
    margin-bottom: 4rem;
  }

  .icon-box-side {
    margin-bottom: 0;
  }
}

@include mq('xl') {
  .icon-boxes-separator {
    [class*='col-'] + [class*='col-']:before {
      content: '';
      display: block;
      width: 0.1rem;
      height: 5.3rem;
      background-color: #e9e9e9;
      position: absolute;
      left: -0.3rem;
      top: 50%;
      margin-top: -2.55rem;
    }
  }
}

@include mq('xl', 'max') {
  .icon-box-sm br {
    display: none;
  }
}
